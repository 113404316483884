@import '~#/theme/main';

$title-size: vw(35px);

.logo-title {
  display: inline-block;
  flex-grow: 1;
  font-size: $title-size;
  height: $title-size;
  line-height: $title-size;
  line-height: vw(102px);
  padding-left: vw(20px);
  position: absolute;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
}

.logo-title-local {
  display: table-cell;
  font-size: $title-size;
  padding-left: vw(20px);
  vertical-align: middle;
  white-space: nowrap;
}

.logo {
  width: vw(250px);
}

.logo-link {
  height: vw(50px);
  margin-bottom: auto;
  margin-top: auto;
}

.user-control {

  @include responsive(medium-screens) {
    right: vw(96px);
    top: vw(26px);
  }

  position: absolute;
  right: vw(40px);
}

.container {
  box-sizing: border-box;
  color: var(--coloronbackground);
  padding: 0 $header-base;
  padding: 2.5rem !important;
  position: absolute;
  visibility: hidden;
  width: 100%;
  z-index: $header-container-z-index;

  &.is-visible {
    visibility: visible;
  }

  &.fullscreen {
    position: absolute;
  }

  // stylelint-disable-next-line selector-class-pattern
  :global(.triState) button {
    &::before {

      @include pseudo;

      border-bottom: solid-border();
      height: 70%; // Tune this to match the design
      width: size-without-border(10px);
    }
  }
}
